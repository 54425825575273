/** Generated by FG **/

@font-face {
	
	font-family: "Trajan Pro";
	src: url('998687745-TrajanPro-Regular.eot');
	src: url('998687745-TrajanPro-Regular.eot?#iefix') format('embedded-opentype'),
	url('998687745-TrajanPro-Regular.woff') format('woff'),
	url('998687745-TrajanPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	
}


@font-face {
	
	font-family: "Helvetica Neue";
	src: url('61370850-HelveticaNeue_1.eot');
	src: url('61370850-HelveticaNeue_1.eot?#iefix') format('embedded-opentype'),
	url('61370850-HelveticaNeue_1.woff') format('woff'),
	url('61370850-HelveticaNeue_1.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	
}


@font-face {
	
	font-family: "Myriad Pro";
	src: url('507537842-MyriadPro-Regular.eot');
	src: url('507537842-MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
	url('507537842-MyriadPro-Regular.woff') format('woff'),
	url('507537842-MyriadPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	
}

.pageTitle {
    font-family: "Trajan Pro";
    font-size: 34px;
    font-weight: 700;
    text-align: center;
}

.seasonVerse {
    text-align: center;
}

.breadcrumbDiv {
    margin-top: 40px;
    margin-bottom: 20px;
}
.spinnerDiv {
    display: flex;
    justify-content: center;
    padding: 100px;
}

.loading-spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #454545;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*
================================
Embedded fonts
================================
*/
@font-face  {
    font-family: 'CS Avva Shenouda';
    src: url('../fonts/cs_avva_shenouda.ttf') format('truetype');
    font-weight: normal;
    font-style: normal; 
}
@font-face {
    font-family: 'CS New Athanasius';
    src: url('../fonts/cs_new_athanasius.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hazzat';  
    src: url('../fonts/hazzat1_10a.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hazzat Vertical';
    src: url('../fonts/HazzatV.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hazzat Arabic';
    src: url('../fonts/HazzatA.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hazzat Vertical Arabic';
    src: url('../fonts/HazzatVA.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*
================================
Hymn text Styles
================================
.HymnTitle    { COLOR: navy; text-decoration: none; font-family: ARIAL,verdana; FONT-SIZE:18px; FONT-WEIGHT: bold; background-color: #EFF3FB; margin-top: 7px; margin-bottom: 7px; padding-top: 7px; padding-bottom: 7px; }

*/

.HymnTitle { padding:0px 0px 22px; font-family: "Trajan Pro"; font-size:22px; font-weight:700; padding-bottom:10px; }

:root {
    --english-font-size: 18px;
    --arabic-font-size: 22px;
    --content-font-color: BLACK;
    --hazzat-font-color: BLACK;
}

.CopticFont {
    COLOR: var(--content-font-color);
    text-decoration: none;
    font-family: 'CS Avva Shenouda', 'CS New Athanasius';
    font-size: var(--english-font-size);
}
.HazzatFont {
    COLOR: var(--hazzat-font-color);
    text-decoration: none;
    font-family: 'Hazzat';
    font-size: var(--english-font-size);
}
.HazzatVFont {
    COLOR: var(--hazzat-font-color);
    text-decoration: none;
    font-family: "Hazzat Vertical";
    font-size: var(--english-font-size);
}
.HazzatAFont {
    COLOR: var(--hazzat-font-color);
    text-decoration: none;
    font-family: 'Hazzat Arabic';
    font-size: var(--arabic-font-size);
    direction: rtl;
}
.HazzatVAFont {
    COLOR: var(--hazzat-font-color);
    text-decoration: none;
    font-family: 'Hazzat Vertical Arabic';
    font-size: var(--arabic-font-size);
    direction: rtl;
}
.EnglishFont {
    COLOR: var(--content-font-color);
    text-decoration: none;
    font-family: ARIAL,verdana;
    font-size: var(--english-font-size);
}
.ArabicFont {
    COLOR: var(--content-font-color);
    text-decoration: none;
    font-family: 'Arabic Transparent';
    font-size: var(--arabic-font-size);
    direction: rtl;
}
.EnglishComment  { COLOR: RED; text-decoration: none; font-family: ARIAL,verdana; FONT-SIZE:14px; font-style:italic;}
.CopticComment   { COLOR: RED; text-decoration: none; font-family: 'CS Avva Shenouda', 'CS New Athanasius'; FONT-SIZE:14px; font-style: italic;}
.ArabicComment   { COLOR: RED; text-decoration: none; font-family: 'Arabic Transparent'; FONT-SIZE:16px; font-style: italic;direction:rtl;}
.HymnList { width: 16px; border: 0px none transparent; }

@keyframes bounceAnimation {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}

.bounce {
    animation: bounceAnimation 0.3s linear;
}
